import React from "react";
import Slider from "@mui/material/Slider";
import "./CloudConnectBackup.css";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import CloudConnectJson from "./PriceFiles/VCCBackup.json";
import Box from "@mui/material/Box";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import VirtualDCS from "../src/Images/virtualDCS-logo.png";
import Veeam from "../src/Images/veeam.png";
export const CloudConnectBackup = (props) => {
  const [contractLength, setContractLength] = React.useState("0");
  const [amount, setAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [dataValue, setDataValue] = React.useState("");

  const handleSliderChange = (event, newValue) => {
    setDataValue(newValue);
    calculatePrice(newValue, contractLength);
  };

  const calculatePrice = (newValue, contractLength) => {
    var cost = 0;
    var slideqty = newValue * 1000;
    for (let index = 0; index < CloudConnectJson.DiskBand.length; index++) {
      const element = CloudConnectJson.DiskBand[index];
      if (slideqty <= JSON.parse(element.Start)) {
        cost = slideqty * element.Price;
        break;
      }
    }
    if (slideqty === 0) {
      setAmount(0);
      setTotal(0);
    } else {
      var disc = 1 - Number(contractLength) / 100;
      var amount = Number(cost / slideqty);
      setAmount((amount * disc).toFixed(4));
      setTotal((cost * disc).toFixed(2));
    }
  };

  const contractLengthChange = (event) => {
    var targetValue = event.target.value;
    setContractLength(targetValue);
    console.log(dataValue);
    calculatePrice(dataValue, targetValue);
  };

  const handleInputChange = (event) => {
    var targetValue =
      event.target.value === "" ? 0 : Number(event.target.value);

    if (targetValue > 25) {
      targetValue = 25;
      setDataValue(25);
    } else {
      setDataValue(targetValue);
    }
    calculatePrice(targetValue, contractLength);
  };

  const handleBlur = () => {
    if (dataValue < 0) {
      setDataValue(0);
    } else if (dataValue > 25) {
      setDataValue(25);
    }
  };

  function valuetext(value) {
    return `${value} TB`;
  }

  const marks = [
    {
      value: 0,
      label: "0 TB",
    },
    {
      value: 25,
      label: "25 TB",
    },
  ];

  const radioData = [
    {
      id: "1",
      value: "0",
      label: "1 Year",
    },
    {
      id: "2",
      value: "5",
      label: "2 Years",
    },
    {
      id: "3",
      value: "10",
      label: "3 Years",
    },
    {
      id: "5",
      value: "15",
      label: "5 Years",
    },
  ];
  const renderTooltip = (props) => (
    <Tooltip id={`tooltip-${props.id}`} {...props}>
      {props.text}
    </Tooltip>
  );
  return (
    <>
    <Row className="p-3 pe-4" style={{ borderRadius: "8px" }}>
      <Col sm={8}>
        <Card style={{ borderColor: "white" }}>
          <Card.Body>
            <Row>
              <Col sm={12} className="justify-content-start d-flex">
                <span className="font-weight-bold textprimary me-2 labels" style={{fontSize:'1.25rem'}}>
                  Data to protect offsite
                </span>
                <OverlayTrigger
                  key="sort-1"
                  placement="bottom"
                  delay={{ show: 250, hide: 200 }}
                  overlay={(props) =>
                    renderTooltip({
                      ...props,
                      id: "sort-1",
                      text: "Estimate the amount of off-site disk space you require to store your data.i.e. If you already have some, how large are your local vbk files?",
                    })
                  }
                >
                  <InfoCircleFill color="#5091ce" />
                </OverlayTrigger>
              </Col>
              <Col sm={10}>
                <Slider
                  min={0}
                  max={25}
                  step={0.5}
                  getAriaValueText={valuetext}
                  value={typeof dataValue === "number" ? dataValue : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  valueLabelDisplay="auto"
                  marks={marks}
                />
              </Col>
              <Col sm={2}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    value={dataValue}
                    placeholder="0"
                    aria-label="TB"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    min="0"
                    max="25"
                    step="0.5"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">TB</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col sm={10}>
                <RadioGroup
                  orientation="horizontal"
                  aria-labelledby="segmented-controls-example"
                  name="contractLength"
                  value={contractLength}
                  onChange={contractLengthChange}
                  sx={{
                    minHeight: 48,
                    padding: "6px",
                    borderRadius: "12px",
                    bgcolor: "neutral.300",
                    "--RadioGroup-gap": "20px",
                    "--Radio-actionRadius": "8px",
                  }}
                >
                  {radioData.map((item) => (
                    <Radio
                      key={item.id}
                      color="neutral"
                      value={item.value}
                      disableIcon
                      label={item.label}
                      variant="plain"
                      sx={{
                        px: 4,
                        alignItems: "center",
                        "& + span": {
                          color: (theme) =>
                            contractLength === item.value
                              ? theme.palette.primary.main
                              : "inherit",
                        },
                      }}
                      slotProps={{
                        action: ({ checked }) => ({
                          sx: {
                            ...(checked && {
                              bgcolor: "background.surface",
                              boxShadow: "sm",
                              "&:hover": {
                                bgcolor: "background.surface",
                              },
                            }),
                          },
                        }),
                      }}
                    />
                  ))}
                </RadioGroup>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={4} className="modern-card">
        {props.isBlurred ? (
          <>
            
            <div className={`blurred-card blur bllurred-one p-2`}>
              <Card className="shadow-sm rounded-lg">
                <Card.Body>
                  <Card.Title className="font-weight-bold textprimary">
                    Price
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;0/ GB
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Total price excluding VAT
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;0/ mo
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div
              onClick={props.handleShow}
              className="heartbeat p-2 ps-4 pe-4 one"
            >
              <span>Show Pricing</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="p-2"
              style={{
                backgroundImage: "linear-gradient(to right, #6dd6eb, #0c33a6)",
                borderRadius: "13px",
                width:'100%'
              }}
            >
              <Card className="shadow-sm rounded-lg">
                <Card.Body>
                  <Card.Title className="font-weight-bold textprimary">
                    Price
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{amount}/ GB
                  </Card.Text>
                  <Card.Title className="font-weight-bold textprimary mt-3">
                    Total price excluding VAT
                  </Card.Title>
                  <Card.Text className="font-weight-medium">
                    £&nbsp;{total}/ mo
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </>
        )}

        {/* <Card className="shadow-sm rounded-lg">
          <Card.Body>
            <Card.Title className="font-weight-bold textprimary">
              Price
            </Card.Title>
            <Card.Text className="font-weight-medium">
              £&nbsp;{amount}/ GB
            </Card.Text>
            <Card.Title className="font-weight-bold textprimary mt-3">
            Total price excluding VAT
            </Card.Title>
            <Card.Text className="font-weight-medium">
              £&nbsp;{total}/ mo
            </Card.Text>
          </Card.Body>
        </Card> */}
      </Col>
    </Row>
    <Box className="p-3 pt-0 scaleup">
    <div
      className="bg-white p-3"
      style={{ borderRadius: "8px", border: "1px solid #5091ce" ,textAlign:'left'}}
    >
      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted me-1">* Click <a href="#" style={{fontSize:'13px'}} className="text-primary text-decoration-underline me-1" onClick={props.handleRequestShow} >HERE
        </a>to claim a 30-day trial of Veeam Cloud Connect.</span>
      </div>
      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted">
          * 14 days of insider threat protection is included as standard.
        </span>
      </div>
      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted">
          * Immutable storage repositories available at no extra cost.
        </span>
      </div>

      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted">
          * This pricing is intended as a guide, and we are happy to discuss your requirements in more detail.
        </span>
      </div>
      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted">
          * No ingress or egress charges.
        </span>
      </div>
      <div className="col-12 justify-content-start d-flex">
        <span className="text-muted me-1">
          * For full service terms and conditions click <a href="https://www.virtualdcs.co.uk/downloads" style={{fontSize:'13px'}} className="text-primary text-decoration-underline"> HERE.</a>
          </span>
          
      </div>
    </div>
  </Box></>
  );
};

export default CloudConnectBackup;
